<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $tc('actions.filter', 2) }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('luna.face.gender') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="genderFilter"
            :options="genderOptions"
            class="w-100"
            multiple
            :reduce="val => val.value"
            @input="(val) => $emit('update:genderFilter', val)"
          />
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t('luna.face.age') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="ageFilter"
            :options="ageOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:ageFilter', val)"
          />
        </b-col>
        <b-col
          cols="3"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $t("luna.face.similarity_match") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="faceIdFilter"
            :options="faceIdOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:faceIdFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    genderOptions: {
      type: Array,
      default: null,
    },
    genderFilter: {
      type: Array,
      default: null,
    },
    ageOptions: {
      type: Array,
      default: null,
    },
    ageFilter: {
      type: Array,
      default: null,
    },
    faceIdOptions: {
      type: Array,
      default: null,
    },
    faceIdFilter: {
      default: null,
    },
  },
};
</script>