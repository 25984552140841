import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch('luna_face_detection/getAll')
  const faceIdOptions = computed(() => store.getters['luna_face_detection/getSelectedItems'])
  const faceIdFilter = ref(null)

  const genderOptions = ([
    { value: 0, label: 'Mujer'},
    { value: 1, label: 'Hombre'}
  ]);
  const genderFilter = ref(null)
  
  const ageOptions = ([
    {value: "18,29", label: '18 - 29 años'},
    {value: "30,49", label: '30 - 49 años'},
    {value: "50,69", label: '50 - 69 años'},
    {value: "70,89", label: '70 - 89 años'},
  ]);
  const ageFilter = ref(null)

  const tableColumns = [
    { label: 'luna.face.image', key: 'image', sortable: false },
    { label: 'camera.title', key: 'camera_proxy.camera.name', sortable: true },
    { label: 'luna.face.age', key: 'age', sortable: true },
    { label: 'luna.face.gender', key: 'gender', sortable: true, formatter: (value, key, item) => {
      if(item.gender) return 'Masculino'
      return 'Femenino'
      }
    },
    { label: 'luna.face.predominant_ethnicity', key: 'predominant_ethnicity', sortable: true },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("luna_face_detection/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        gender: genderFilter.value,
        age: ageFilter.value,
        face_id: faceIdFilter.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([genderFilter, ageFilter, faceIdFilter, currentPage, perPage, ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    genderOptions,
    genderFilter,
    ageOptions,
    ageFilter,
    faceIdOptions,
    faceIdFilter,
    refetchData,
  };
}
