<template>
  <div>
    <toastification-loading-content ref="loadingToast" />
    <toastification-progress-content ref="progressToast" />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <filters
      :gender-filter.sync="genderFilter"
      :gender-options="genderOptions"
      :age-filter.sync="ageFilter"
      :age-options="ageOptions"
      :face-id-filter.sync="faceIdFilter"
      :face-id-options="faceIdOptions"
    />
    <b-card-group deck>
      <!-- Table Container Card -->
      <b-card class="m-2" no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label class="text-capitalize">{{ $t("table.show") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label class="text-capitalize">{{ $t("table.entries") }}</label>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('no_record_found')"
          :sort-desc.sync="isSortDirDesc"
          @row-selected="onRowSelected"
          select-mode="single"
          selectable
        >
          <template #head()="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #head(actions)="data">
            <span> {{ $tc(data.label, 2) }}</span>
          </template>

          <template #cell(detection)="data">
            <pre style="height: 10rem; width: 25rem; overflow: auto">
            {{ JSON.parse(data.item.detection) }}
          </pre
            >
          </template>

          <template #cell(image)="data">
            <div class="hoverable">
              <b-img
                :src="data.item.url_image "
                alt="path Image"
                width="85"
                height="100"
              ></b-img>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="openEdit(data.item)"
                v-if="$can('update', 'LunaFace')"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="confirmDelete(data.item.id)"
                v-if="$can('delete', 'LunaFace')"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <Pagination
                :totalList="totalList"
                :currentPage="currentPage"
                @updatePagination="update"
                :perPage="perPage"
              >
              </Pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <!-- Modal -->
      <b-card class="m-2" v-if="selectedObject" no-body>
        <feather-icon
          icon="XIcon"
          class="mr-2 ml-auto mt-2 float-rigth"
          style="cursor: pointer"
          size="30"
          @click="selectedObject = null"
        />
        <div class="mx-2">
          <div class="w-100 mx-auto">
            <div class="row">
              <div class="col-12 mb-2">
                <img
                  class="img-thumbnail"
                  :src="`${selectedObject.url_image}`"
                  alt=""
                />
              </div>
            </div>
          </div>
          <table class="table table-sm">
            <tr>
              <th class="text-capitalize">{{ $tc("camera.title", 1) }}</th>
              <td>{{ selectedObject.camera_proxy.camera.name }}</td>
            </tr>
            <tr>
              <th class="text-capitalize">{{ $t("luna.face.age") }}</th>
              <td>{{ selectedObject.age }}</td>
            </tr>
            <tr>
              <th class="text-capitalize">{{ $t("luna.face.detection") }}</th>
              <td>{{ selectedObject.count}}</td>
            </tr>
            <tr>
              <th class="text-capitalize">{{ 'id '+$t("luna.face.title") }}</th>
              <td>{{ selectedObject.face_id}}</td>
            </tr>
          </table>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import ToastificationProgressContent from "@core/components/toastification/ToastificationProgressContent.vue";
import axios from "@axios";

export default {
  components: {
    Filters,
    Breadcrumb,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
    ToastificationProgressContent,
    Pagination,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start breadcrumb
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("luna.face_detection.title");

    const { t } = useI18nUtils();
    const toast = useToast();
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      genderOptions,
      genderFilter,
      ageOptions,
      ageFilter,
      faceIdFilter,
      faceIdOptions,
    } = useList();

    const selectedObject = ref(null);
    const onRowSelected = (item) => {
      if (item.length > 0) {
        selectedObject.value = item[0];
        getData();
      } else {
        selectedObject.value = null;
      }
    };

    const getData = () => {
      let lunaFace = [];

      let face_id = null;
      if(selectedObject.value.count_face_id){
        face_id = selectedObject.value.count_face_id
      }
      else{
        face_id = selectedObject.value.face_id
      }

      let params = {
        id: selectedObject.value.id,
        count_face_id: face_id,
        max: 100,
      };
      axios
        .get("v1/services/facial-recognition/luna/luna-face-detection/get-data", {params})
        .then((response) => {
          selectedObject.value = response.data.data
        })
        .catch((error) => {
          console.error(error);
        });
    };

    return {
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      genderOptions,
      genderFilter,
      ageOptions,
      ageFilter,
      update,
      onRowSelected,
      selectedObject,
      faceIdFilter,
      faceIdOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
